import { handleActions } from 'redux-actions';
import produce from 'immer';

const initialState = {
  isLoggedIn: false,
  role: null,
  menus: null,
  key: null,
};

export default handleActions(
  {
    'AUTH/LOGIN': (state, action) =>
      produce(state, (draft) => {
        const { role, menus, key } = action.payload;
        draft.isLoggedIn = true;
        draft.key = key;
        draft.role = role;
        draft.menus = menus;
      }),
    'AUTH/LOGOUT': (state, action) => initialState,
  },
  initialState
);
