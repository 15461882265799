import React, { useState, useRef } from 'react';
import classNames from 'classnames/bind';
import { useDispatch } from 'react-redux';
import useMountEffect from '@restart/hooks/useMountEffect';

import USERS from '../constants/users';
import styles from './Form.module.scss';
import actions from '../actions';

const cx = classNames.bind(styles);

const Form = () => {
  const dispatch = useDispatch();
  const [account, setAccount] = useState({
    id: '',
    password: '',
  });
  const [errorMessage, setErrorMessage] = useState('');
  const [isProcessing, setProcessing] = useState(false);
  const idRef = useRef();
  const passwordRef = useRef();

  useMountEffect(() => {
    idRef.current.focus();
  });

  const handleChange = (e) => {
    setAccount({
      ...account,
      [e.target.name]: e.target.value,
    });
    setErrorMessage('');
  };

  const pressEnter = (e) => {
    if (e.key === 'Enter') {
      switch (e.target.name) {
        case 'id':
          passwordRef.current.focus();
          break;

        case 'password':
          doLogin();
          break;

        default:
          break;
      }
    }
  };

  // 유효성 검사
  const validate = () => {
    if (account.id.trim().length === 0) {
      setErrorMessage('아이디를 입력해 주세요.');
      idRef.current.focus();
      return false;
    }
    if (account.password.trim().length === 0) {
      setErrorMessage('비밀번호를 입력해 주세요.');
      passwordRef.current.focus();
      return false;
    }
    return true;
  };

  // 로그인
  const doLogin = () => {
    if (isProcessing) return;
    if (!validate()) return;

    setErrorMessage('');
    setProcessing(true);

    Object.values(USERS).forEach((user) => {
      if (account.id === user.id && account.password === user.password) {
        setTimeout(() => {
          localStorage.setItem('@ims/isLoggedInfo', JSON.stringify({ ...user.info, key: user.key }));
          setProcessing(false);
          dispatch(actions.auth.login(user.info.role, user.info.menus, user.key));
        }, 1000);
      } else {
        setTimeout(() => {
          setProcessing(false);
          setErrorMessage('아이디 또는 비밀번호를 다시 확인해 주세요.');
        }, 1000);
      }
    });
  };

  return (
    <div className={cx('container')}>
      <div className={cx('logo')} />
      <div className={cx('fieldsWrapper')}>
        <input
          name="id"
          type="text"
          ref={idRef}
          value={account.id}
          disabled={isProcessing}
          onChange={handleChange}
          onKeyDown={pressEnter}
          placeholder="ID"
          className={cx('field')}
        />
        <input
          name="password"
          type="password"
          ref={passwordRef}
          value={account.password}
          disabled={isProcessing}
          onChange={handleChange}
          onKeyDown={pressEnter}
          placeholder="Password"
          className={cx('field')}
        />
      </div>
      <div className={cx('message')}>{errorMessage}</div>
      <div className={cx(['button', { isProcessing }])} onClick={doLogin}>
        로그인
        <div className={cx('spinner')} />
      </div>
    </div>
  );
};

export default Form;
