import React from 'react';
import classNames from 'classnames/bind';
import { useSelector, useDispatch } from 'react-redux';
import useMountEffect from '@restart/hooks/useMountEffect';

import styles from './index.module.scss';
import actions from '../actions';
import Form from './Form';
import Menu from './Menu';

const cx = classNames.bind(styles);

const Page = () => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

  useMountEffect(() => {
    const userInfo = JSON.parse(localStorage.getItem('@ims/isLoggedInfo'));

    if (userInfo) {
      const { role, menus, key } = userInfo;
      dispatch(actions.auth.login(role, menus, key));
    } else {
      dispatch(actions.auth.logout());
    }
  });

  return (
    <div className={cx('container')}>
      {isLoggedIn === false && <Form />}
      {isLoggedIn === true && <Menu />}
    </div>
  );
};

export default Page;
