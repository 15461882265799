import React, { useState } from 'react';
import classNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import { IoCloseOutline } from 'react-icons/io5';
import useMountEffect from '@restart/hooks/useMountEffect';
import axios from 'axios';

import styles from './Menu.module.scss';
import actions from '../actions';

const cx = classNames.bind(styles);

const Menu = () => {
  const dispatch = useDispatch();
  const menus = useSelector((state) => state.auth.menus);
  const key = useSelector((state) => state.auth.key);
  const [visible, setVisible] = useState(false);

  useMountEffect(() => {
    setTimeout(() => setVisible(true), 10);
  });

  const goSMS = () => {
    if (menus.isSms) {
      window.alert('접근할 권한이 없습니다.');
      return;
    }
    axios
      .get('https://api.h2sms.bte-inc.co.kr/autologin/ticket', {
        headers: {
          'x-api-key': key,
        },
      })
      .then((response) => response.data)
      .then(({ success, data }) => {
        if (success) {
          window.open(`https://h2sms.bte-inc.co.kr/autologin?ticket=${data.ticket}`);
        }
      });
  };

  const goTMS = () => {
    if (menus.isTms) {
      window.alert('접근할 권한이 없습니다.');
      return;
    }
    axios
      .get('https://api.h2tms.bte-inc.co.kr/bo/autologin/ticket', {
        headers: {
          'x-api-key': 'xzQ07xNVoXsI91xxuWtOlTo7klAcm8dkxkL1eN1G',
        },
      })
      .then((response) => response.data)
      .then(({ success, data }) => {
        if (success) {
          window.open(`https://h2tms.bte-inc.co.kr/autologin?ticket=${data.ticket}`);
        }
      });
  };

  const goMMS = () => {
    if (menus.isH2mms) {
      window.alert('접근할 권한이 없습니다.');
      return;
    }
    axios
      .get('https://api.m1ucs.themobilityone.com/fo/auth/autologin/ticket', {
        headers: {
          'x-api-key': '5lowhboapn318n5qzib0',
        },
      })
      .then((response) => response.data)
      .then(({ success, data }) => {
        if (success) {
          window.open(`https://h2mms.bte-inc.co.kr/autologin?ticket=${data.ticket}`);
        }
      });
  };

  // 로그아웃
  const doLogout = () => {
    localStorage.removeItem('@ims/isLoggedInfo');
    dispatch(actions.auth.logout());
  };

  return (
    <div className={cx(['container', { visible }])}>
      <div className={cx('close')} onClick={doLogout}>
        <IoCloseOutline size={24} />
      </div>
      <div className={cx('logo')} />
      <div className={cx('wrapper')}>
        <div className={cx('menu', { disabled: menus.isSms })} onClick={goSMS}>
          <div className={cx('title')}>H2SMS</div>
          <div className={cx('subtitle')}>수소스테이션모니터링시스템</div>
          <div className={cx(['image', 'clip1'])} />
        </div>
        <div className={cx('menu', { disabled: menus.isTms })} onClick={goTMS}>
          <div className={cx('title')}>H2TMS</div>
          <div className={cx('subtitle')}>수소용기관리시스템</div>
          <div className={cx(['image', 'clip2'])} />
        </div>
        <div className={cx('menu', { disabled: menus.isH2mms })} onClick={goMMS}>
          <div className={cx('title')}>H2MMS</div>
          <div className={cx('subtitle')}>수소모빌리티모니터링시스템</div>
          <div className={cx(['image', 'clip3'])} />
        </div>
      </div>
    </div>
  );
};

export default Menu;
