const users = {
  ADMIN: {
    id: 'admin',
    password: '1234',
    key: 'riytt4nsopegi0vkw7mc',
    info: {
      role: 'ADMIN',
      menus: { isSms: false, isTms: false, isH2mms: false },
    },
  },
  SUB_ADMIN: {
    id: 'testbte',
    password: '1234',
    key: '6qn1r1hia0svm0snu8fg',
    info: {
      role: 'SUB-ADMIN',
      menus: { isSms: false, isTms: false, isH2mms: false },
    },
  },
  CUSTOMER: {
    id: 'testdmi',
    password: '1234',
    key: 'yxwxy7w619kp65g1sos2',
    info: {
      role: 'CUSTOMER',
      menus: { isSms: false, isTms: true, isH2mms: true },
    },
  },
  SUB_CUSTOMER: {
    id: 'testdmiuser',
    password: '1234',
    key: 'zr4c3teyhywidkhu807z',
    info: {
      role: 'SUB-CUSTOMER',
      menus: { isSms: false, isTms: true, isH2mms: true },
    },
  },
};

export default users;
